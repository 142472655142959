.wrapper {
  padding: 20px;
  width: 320px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.avatar {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  object-fit: cover;
}
.body {
  margin-top: 12px;
}
.username {
  font-size: 1.8rem;
}
.check {
  margin-left: 4px;
  color: #20d5ec;
}
.name {
  padding: 8px;
  padding-left: 0;
}
.analytics {
}
.followers {
}

.likes {
  margin-left: 20px;
}

.label {
  color: #161823bf;
}
