// menu-wrapper
.menu-items {
  margin-top: 8px;

  min-width: 224px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 12px;
  overflow: hidden;
  .menu-item {
    margin-left: 0;
  }
}

.menu-popper {
  display: flex;
  flex-direction: column;

  padding-bottom: 8px;
}

.menu-body {
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;

  overflow-y: overlay;
}

.menu-item {
  //   width: 100%;
  padding: 11px 16px;
  font-size: 1.6rem !important;

  &.separate {
    border-top: 1px solid rgba(22, 24, 35, 0.12);
  }
  &:hover {
    background-color: rgba(22, 24, 35, 0.03);
  }
}

// header
.header {
  flex-shrink: 0;
  margin-top: -8px;
  position: relative;
  height: 50px;
}

.back-btn {
  width: 60px;
  height: 100%;
  background: transparent;
  cursor: pointer;
}
.header-title {
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
