.wrapper {
  border-top: 1px solid #ccc;
}

.title {
  font-size: 1.4rem;
  font-weight: 600;
  padding: 8px;
}

// account item
.account-item {
  display: flex;
  padding: 8px;
  cursor: pointer;
}

.avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.item-info {
  flex: 1;
  margin-left: 12px;
}
.username {
  font-size: 1.6rem;
}

.check {
  font-size: 1.4rem;
  color: #20d5ec;
  padding-left: 4px;
}
.name {
  font-size: 1.2rem;
}


// see more btn

.see-more{
  color: var(--primary);
  padding: 8px;
  font-size: 1.4rem;
  font-weight: 700;
  cursor: pointer;
}