@import 'normalize.css';

// @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --primary: #fe2c55;
  --black: #000;
  --white: #fff;
  --text-color: #000;
  --default-layout-header-height: 60px;
  --default-layout-width: 100%;
  --default-layout-horizontal-spacer: 16px;
  --search-input-width-homepage: 500px;
  --font-family: 'IBM Plex Sans', 'TikTokFont', 'Arial', 'Tahoma', 'PingFangSC', 'Montserrat', 'Roboto', 'sans-serif';
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-family: var(--font-family);
  font-size: 1.6rem;
  line-height: 1.5rem;
  text-rendering: optimizeSpeed;
  color: var(--text-color);
  overflow-y: overlay;
  height: 500px;
}

// scrollbar

html ::-webkit-scrollbar {
  border-radius: 0px;
  width: 8px;
}

html ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(22, 24, 35, 0.06);
}

html ::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: rgba(0, 0, 0, 0);
}

// fonts emmbed
@font-face {
  font-family: 'IBM Plex Sans';
  src: url('../../assets/fonts/TikTokDisplayFont-Bold.woff2');
  font-weight: 700;
}
@font-face {
  font-family: 'IBM Plex Sans';
  src: url('../../assets/fonts/TikTokFont-Bold.woff2');
  font-weight: 700;
}
@font-face {
  font-family: 'IBM Plex Sans';
  src: url('../../assets/fonts/TikTokFont-Regular.woff2');
  font-weight: 400;
}
@font-face {
  font-family: 'IBM Plex Sans';
  src: url('../../assets/fonts/TikTokFont-Semibold.woff2');
  font-weight: 600;
}

button,
input {
  background-color: transparent;
  outline: none;
  border: none;
}

a {
  color: var(--text-color);
  text-decoration: none;
}

body {
  .tippy-box {
    top: 8px;
    background-color: rgba(84, 84, 84, 0.92);
  }

  .tippy-arrow {
    color: rgba(84, 84, 84, 0.92);
  }
}
