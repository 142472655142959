.recommend-item-wrapper {
  max-width: 692px;
  height: calc(-150px + 100vh);
  display: flex;
  flex-direction: row;
  padding: 20px 0;
  position: relative;
  margin: 0 auto;
  scroll-snap-stop: always;
  scroll-snap-align: start center;
  align-items: start;
  margin-bottom: 20px;
}
.content-wrapper {
  margin-left: 68px;
  width: 624px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

//header wrapper
.header-wrapper {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  gap: 10px;
}
.avatar {
  position: absolute;
  left: 0px;
  width: 56px;
  height: 56px;
  // border-radius: 50%;
}
.info {
}
.author {
}
.desc {
  margin-top: 4px;

  a {
    margin-right: 8px;
    color: #2d5bd9;
  }

  a:hover {
    text-decoration: underline;
  }

  a:first-child {
    text-decoration: none;
    color: var(--black);
  }
}
.button {
  flex-shrink: 0;
  min-width: 96px;
}

// video wrapper
.video-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 100%;
  margin: unset;
}
.video-card-wrapper {
  cursor: pointer;
  margin-right: 20px;
  height: 100%;
}
.video-player {
  height: 100%;
  position: relative;
}
.video {
  height: 100%;
  border-radius: 16px;
  overflow: hidden;
}
.video-action {
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  color: var(--white);
  align-items: center;
}

.play-btn {
  left: 12px;
  bottom: 12px;
  padding: 10px;
  width: 40px;
  height: 40px;
  transition: opacity 0.3s ease;
  margin-right: 10px;
}

.video-controller {
  width: 100%;
  height: 16px;
  padding-inline: 12px;
  position: absolute;
  opacity: 1;

  transition: opacity 0.3s ease 0s;
  bottom: 14px;
  cursor: initial;
  display: flex;
}

.seekbar-container {
  width: 100%;
  height: 16px;
  flex: 1 1 auto;
  position: relative;
}
.slider {
  height: 2px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.34);
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

// action button
.action-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > * {
    cursor: pointer;
  }
}
.avatar-container {
  position: relative;
  margin-bottom: 20px;
}
.avatar-link {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-link,
.span-icon {
  width: 48px;
  height: 48px;

  svg {
    width: 24px;
    height: 24px;
  }
}

.button-follow {
  position: absolute;
  bottom: 0;
  transform: translateY(50%);
}

.author-avatar {
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 50%;
}

.button-action-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.span-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  background-color: rgba(22, 24, 35, 0.06);
  margin-top: 8px;
  margin-bottom: 6px;
}
.button-action-item {
}

.strong-text {
}
