.wrapper {
  --search-box-border: 92px;
  --search-btn-width: 52px;

  z-index: 1;

  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: var(--default-layout-header-height);
  background-color: var(--white);
  display: flex;
  justify-content: center;

  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px;
}

.inner {
  height: 100%;
  width: var(--default-layout-width);
  padding: 0 var(--default-layout-horizontal-spacer);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
}

.action {
  display: flex;
  align-items: center;
}

.action-btn {
  font-size: 2.4rem;
  color: #161823;
  cursor: pointer;
  padding: 4px 12px;
}

.user-avatar {
  width: 32px;
  height: auto;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
  margin-left: 12px;
}

.btn-more {
  font-size: 2rem;
  margin-left: 24px;
  background-color: transparent;
  padding: 8px;
  cursor: pointer;
}
