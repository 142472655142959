.wrapper {
  // width: 300px;
  max-height: min(-156px + 100vh, 734px);
  margin-top: 8px;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 12px;
  border-radius: 8px;
  padding: 8px 10px;
  min-height: 100px;
  box-sizing: border-box;
}
