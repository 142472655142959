// search

.search,
.search-result {
  width: var(--search-input-width-homepage);
}

.search-title {
  padding: 5px 12px;
  font-size: 1.4rem;
  font-weight: 600;
}

.search {
  position: relative;
  height: 46px;
  display: flex;
  background-color: rgba(22, 24, 35, 0.06);
  border-radius: var(--search-box-border);
  padding-left: 16px;
  border: 1.5px solid transparent;
  input {
    caret-color: var(--primary);
    flex: 1;
    height: 100%;
    padding: 12px;
    color: #828282;
    font-size: 1.6rem;
    background-color: transparent;
    font-family: var(--font-family);
  }
  input:not(:placeholder-shown) ~ .search-btn {
    color: rgb(85, 82, 82);
  }
  &::after {
    content: '';
    position: absolute;
    height: 28px;
    width: 1px;
    right: var(--search-btn-width);
    top: 9px;
    background-color: rgba(22, 24, 35, 0.34);
  }
  &:focus-within {
    border-color: #123;
  }

  &:hover{
    border-color: #123;
  }
}

.clear-search,
.loading {
  position: absolute;
  right: calc(var(--search-btn-width) + 20px);
  top: 50%;
  transform: translateY(-50%);
  color: rgb(85, 87, 88);
}

.loading {
  animation: mymove 0.3s linear infinite;
}

@keyframes mymove {
  from {
    transform: translateY(-50%) rotate(0);
  }
  to {
    transform: translateY(-50%) rotate(180deg);
  }
}

.search-btn {
  border-bottom-right-radius: var(--search-box-border);
  border-top-right-radius: var(--search-box-border);
  width: var(--search-btn-width);
  height: 100%;
  font-size: 1.8rem;
  color: rgba(22, 24, 35, 0.34);
  &:hover {
    cursor: pointer;
    background-color: rgba(22, 24, 35, 0.03);
  }
  &:active {
    background-color: rgba(22, 24, 35, 0.06);
  }
}
