.wrapper {
  display: inline-flex;
  align-items: center;
  // justify-content: center;
  font-size: 1.6rem;
  font-weight: 700;
  min-width: 100px;
  border-radius: 4px;
  padding: 9px 16px;
  cursor: pointer;
  background-color: var(--white);
  border: 1px solid transparent;
  user-select: none;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.primary,
.outlined,
.rounded {
  justify-content: center;
}

.primary {
  background-color: var(--primary);
  color: var(--white) !important;

  &:hover {
    background-color: rgb(189, 63, 63);
  }
}
.outlined {
  color: var(--primary) !important;
  border-color: var(--primary);

  &:hover {
    background-color: rgb(252, 215, 215);
  }
}

.rounded {
  border: 1px solid rgba(22, 24, 35, 0.12);
  background: none rgb(255, 255, 255);
  outline: none;
  color: rgba(22, 24, 35, 0.75);
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 14px;
  position: relative;
  right: 0px;
  bottom: 0px;
  padding: 0px 8px;
  min-width: 90px;
  height: 32px;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 8px;
  border-radius: 52px;
  cursor: pointer;
  line-height: 30px;
  text-align: center;
  white-space: nowrap;
  transition: transform 0.3s;
  &:hover {
    border-color: rgba(22, 24, 35, 0.2);
    background-color: rgb(255, 255, 255);
  }
}

.small {
  min-width: 88px;
  padding: 4px 16px;
}

.large {
  min-width: 150px;
  padding: 14px 16px;
}

.icon + .title,
.title + .icon {
  margin-left: 8px;
}

.icon {
  display: inline-block;
  width: 24px;
  text-align: center;
}
