.menu-item {
  font-family: var(--font-family);
  display: flex;
  font-size: 1.8rem;
  font-weight: 700;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  transition: background-color 200ms ease-in-out 0s;

  &.active {
    color: var(--primary);
  }

  &:hover {
    background-color: rgba(22, 24, 35, 0.03);
  }
}

.icon {
  text-align: center;
  width: 3.2rem;
  height: 3.2rem;
}

.title {
  margin-left: 8px;
}
