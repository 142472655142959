.wrapper {
  display: flex;
  align-items: center;
  padding: 9px 16px;

  &:hover {
    background-color: rgba(22, 24, 35, 0.03);
  }
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}
.info {
  flex: 1;
  margin-left: 12px;
}
.username {
  font-size: 1.6rem;
  font-weight: 600;
}
.full-name {
}

.blue-check {
  margin-left: 8px;
  color: rgb(32, 213, 236);
}
