.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--default-layout-header-height);
}

.container {
  width: var(--default-layout-width);
  display: flex;
}

.content {
  flex: 1;
}
